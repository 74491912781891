const images = {
    americaFlag: require('./americaFlag.png'),
    vietnamFlag: require('./vietnamFlag.png'),
    haha: require('./haha.png'),
    wow: require('./wow.png'),
    like: require('./like.png'),
    love: require('./love.png'),
    angry: require('./angry.png'),
    sad: require('./sad.png'),
    quickMessage: require('./quick-message.png'),
    vanish: require('./vanish.png'),
    welcome: require('./inapp-welcome-screen.svg').default,
    transferMessages: require('./transfer-messages-double-devices.svg').default,
    groupCall: require('./group-call.png'),
    syncMessage: require('./sync-message.png'),
    heavyFiles: require('./heavy-files.png'),
    teammates: require('./teammates.png'),
    enhance: require('./enhance.png'),
    notFoundBg: require('./not-found-bg.gif'),
    searchEmpty: require('./search-empty.png'),
    mentionAll: require('./mention-all.png'),
    imageVerify: require('./image_vetify.png'),
    invitationEmpty: require('./addInvitationEmptyListImage.png'),
    chatTipIconKey: require('./chat-tip-icon-key.png'),
    chatTipIconKeyDel: require('./chat-tip-icon-key-del.png'),
    chatPrimaryKey: require('./chat-primary-key.png'),
    artBoard: require('./artboard.png'),
    pinMessage: require('./ic_pin.png'),
    unpinMessage: require('./ic_unpin.png'),
    defaultBackground: require('./default_background.jpg'),
};

export default images;
