const genders = [
    {
        label: 'register.male',
        value: 'male',
    },
    {
        label: 'register.female',
        value: 'female',
    },
];

export default genders;
