(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("@material/dom", [], factory);
	else if(typeof exports === 'object')
		exports["dom"] = factory();
	else
		root["mdc"] = root["mdc"] || {}, root["mdc"]["dom"] = factory();
})(this, function() {
return 